<template>
    <section class="content-wrapper">
        <section class="content-header">
            <h1>
                Create Subscription
            </h1>
            <ol class="breadcrumb">
                <!-- <li class="breadcrumb-item"><a href="#"><i class="iconsmind-Library"></i></a></li>
                <li class="breadcrumb-item"><a href="#">Examples</a></li>
                <li class="breadcrumb-item active">Contact</li> -->
            </ol>
        </section>
        <section class="content">


            <div class="row">
                <div class="col">
                    <div class="box box-solid box-primary">
                        <div class="box-header with-border">
                            <h4 class="box-title">Create Subscription</h4>
                            <h6 class="box-subtitle"></h6>
                        </div>
                        <!-- /.box-header -->
                        <div class="box-body">
                            <form @submit.prevent="onSubmit" class="form-horizontal form-element">
                                <div class="col-md-12 mb-20">
                                    <p class="text-danger" v-if="error">{{errorMessage}}</p>
                                </div>
                                <div class="row">
                                    <div class="col-md-6 col-lg-6">
                                        <div class="form-group">
                                            <input v-model="subscriptionName" type="text" class="form-control" placeholder="Subscription Name">
                                            <p class="error" v-if="subscriptionNameError">{{subscriptionNameErrorMessage}}</p>
                                        </div>
                                        <div class="form-group">
                                            <input v-model="subscriptionOrderLimit" type="number" class="form-control" placeholder="Order Limit">
                                            <p class="error" v-if="subscriptionOrderLimitError">{{subscriptionOrderLimitErrorMessage}}</p>
                                        </div>
                                        <div class="form-group">
                                            <label>Destination Type</label>
                                            <select v-model="destinationType" @change="onDestinationTypeChange" class="form-control">
                                                <option value="">Select Destination Type</option>
                                                <option value="within-city">Within City</option>
                                                <option value="within-state">Within State</option>                                
                                                <option value="within-country">Within Country</option> 
                                            </select>
                                            <p class="error" v-if="destinationTypeError">{{destinationTypeErrorMessage}}</p>
                                        </div>
                                        <div v-if="destinationCountryList.length" class="form-group">
                                            <label>Select country</label>
                                            <select v-model="selectedDestinationCountryID" @change="onDestinationCountryChange" class="form-control">
                                                <option value="">Select Country</option>
                                                <option v-for="destinationCountry in destinationCountryList" :key="destinationCountry.id" :value="destinationCountry.id">{{destinationCountry.name}}</option>
                                            </select>
                                            <p class="error" v-if="destinationCountryError">{{destinationCountryErrorMessage}}</p>
                                        </div>
                                        <div v-if="destinationStateList.length" class="form-group">
                                            <label>Select State</label>
                                            <select v-model="selectedDestinationStateID" @change="onDestinationStateChange" class="form-control">
                                                <option value="">Select State</option>
                                                <option v-for="destinationState in destinationStateList" :key="destinationState.id" :value="destinationState.id">{{destinationState.name}}</option>
                                            </select>
                                            <p class="error" v-if="destinationStateError">{{destinationStateErrorMessage}}</p>
                                        </div>
                                        <div v-if="boundPlaceList.length" class="form-group">
                                            <label>Select Bound Place</label>
                                            <p class="error" v-if="selectedBoundPlaceError">{{selectedBoundPlaceErrorMessage}}</p>
                                            <div class="demo-checkbox">
                                                <div v-for="boundPlace in boundPlaceList" :key="boundPlace.id">
                                                    <input type="checkbox" :id="boundPlace.id" class="chk-col-red" :value="boundPlace.id" v-model="selectedBoundedPlaces"/>
                                                    <label :for="boundPlace.id">{{boundPlace.name}}</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6 col-lg-6">
                                        <div class="form-group">
                                            <input v-model="subscriptionAmount" type="number" class="form-control" placeholder="Subscription Amount">
                                            <p class="error" v-if="subscriptionAmountError">{{subscriptionAmountErrorMessage}}</p>
                                        </div>
                                        <div class="form-group">
                                            <input v-model="subscriptionValidity" type="number" class="form-control" placeholder="Validity (in days)">
                                            <p class="error" v-if="subscriptionValidityError">{{subscriptionValidityErrorMessage}}</p>
                                        </div>
                                        <div class="form-group">
                                            <label>Pickup Location</label>
                                            <select v-model="pickupLocationType" class="form-control">
                                                <option value="single">Single Location</option>
                                                <option value="multiple">Multiple Location</option>                                
                                            </select>
                                            <p class="error" v-if="pickupLocationTypeError">{{pickupLocationTypeErrorMessage}}</p>
                                        </div>
                                        <!-- <div class="form-group">
                                            <input v-model="subscriptionDiscount" type="number" class="form-control" placeholder="Percentage Discount on orders(e.g 20)">
                                            <p class="error" v-if="subscriptionDiscountError">{{subscriptionDiscountErrorMessage}}</p>
                                        </div> -->
                                        <div class="form-group">
                                            <label>Features</label>
                                            <editor v-model="subscriptionFeatures" api-key="4tu7qtzsooh81aj24yusfadjzdu5lutuuncwqnyemjrd3sam"/>
                                            <p class="error" v-if="subscriptionFeaturesError">{{subscriptionFeaturesErrorMessage}}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="text-xs-right">
                                    <button class="btn btn-info" @click="saveSubscription">Submit<span v-if="loading" class="spinner-border text-white"></span></button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </section>
</template>

<script>
import { repository } from '../../presenter'
import Editor from '@tinymce/tinymce-vue'
export default {
    components: {
        Editor,
    },
  name: 'NewSubscription',
  mounted() {
    const sweetAlert = document.createElement('script')
    sweetAlert.setAttribute('src', '/assets/styles/vendor_components/sweetalert/sweetalert.min.js')
    document.head.appendChild(sweetAlert)
  },
  data () {
    return {
        destinationType: '',
        destinationTypeError: false,
        destinationTypeErrorMessage: '',
        destinationCountryList: [],
        destinationStateList: [],
        boundPlaceList: [],

        
        


        subscriptionName: '',
        subscriptionNameError: false,
        subscriptionNameErrorMessage: '',
        subscriptionOrderLimit: '',
        subscriptionOrderLimitError: false,
        subscriptionOrderLimitErrorMessage: '',
        selectedDestinationCountryID: 0,
        destinationCountryError: false,
        destinationCountryErrorMessage: '',
        selectedDestinationStateID: 0,
        destinationStateError: false,
        destinationStateErrorMessage: '',
        selectedBoundedPlaces: [],
        selectedBoundPlaceError: false,
        selectedBoundPlaceErrorMessage: '',
        subscriptionAmount: '',
        subscriptionAmountError: false,
        subscriptionAmountErrorMessage: '',
        subscriptionValidity: '',
        subscriptionValidityError: false,
        subscriptionValidityErrorMessage: '',
        pickupLocationType: 'single',
        pickupLocationTypeError: false,
        pickupLocationTypeErrorMessage: '',
        subscriptionDiscount: '',
        subscriptionDiscountError: false,
        subscriptionDiscountErrorMessage: '',
        subscriptionFeatures: '',
        subscriptionFeaturesError: false,
        subscriptionFeaturesErrorMessage: '',
        

        loading: false,
        success: false,
        successMessage: '',
        error: false,
        errorMessage: ''
    }
  },
  methods: {

    onDestinationTypeChange(event) {
        this.resetDestinationData();
        if (event.target.value=='within-country'){
        this.showBoundPlace();
        }else{
            this.showCountryDropdown();
        }
    },

    showCountryDropdown() {
        this.loadCountries();
    },
    showStateDropdown(countryID) {
        this.loadStates(countryID);
    },

    onDestinationCountryChange(event) {
        switch(this.destinationType){
            case 'within-city':
            this.showStateDropdown(event.target.value);
            break;
            case 'within-state':
            this.showBoundPlace()
            break;
        }
    },
    onDestinationStateChange(event) {
        switch(this.destinationType){
            case 'within-city':
            this.showBoundPlace();
            break;
        }
    },

    showBoundPlace(){
        switch(this.destinationType){
            case 'within-city':
            this.loadBoundForCity();
            break;
            case 'within-state':
            this.loadBoundForState();
            break;
            case 'within-country':
            this.loadBoundForCountry();
            break;
        }
    },

    async loadCountries() {
        let countryResponse = await repository.regions.fetchCountries();
        if (countryResponse.success){
            this.destinationCountryList = countryResponse.data;
        }
    },
    async loadStates(countryID) {
        const data = {
            countryID: countryID,
        }
        let stateResponse = await repository.regions.fetchStates(data);
        if (stateResponse.success){
            this.destinationStateList = stateResponse.data;
        }
    },
    async loadBoundForCity(){
        const data = {
            stateID: this.selectedDestinationStateID,
        }
        let cityResponse = await repository.regions.fetchCities(data);
        if (cityResponse.success){
            this.boundPlaceList = cityResponse.data;
        }
    },
    async loadBoundForState(){
        const data = {
            countryID: this.selectedDestinationCountryID,
        }
        let stateResponse = await repository.regions.fetchStates(data);
        if (stateResponse.success){
            this.boundPlaceList = stateResponse.data;
        }
    },
    async loadBoundForCountry(){
        let countryResponse = await repository.regions.fetchCountries();
        if (countryResponse.success){
            this.boundPlaceList = countryResponse.data;
        }
    },

    resetDestinationData(){
        this.destinationCountryList = [];
        this.destinationStateList = [];
        this.boundPlaceList = [];
        this.selectedDestinationCountryID = 0;
        this.selectedDestinationStateID = 0;
        this.selectedBoundedPlaces = [];
        this.selectedBoundPlaceError = false;
        this.selectedBoundPlaceErrorMessage = '';
    },
















    async saveSubscription(){
        if (this.loading) return
        this.resetData()
        const subscriptionValidation = this.validateSubscriptionData()
        if (!subscriptionValidation.success) return
        this.loading = true
        const data = {
            subscriptionName: this.subscriptionName,
            subscriptionAmount: this.subscriptionAmount,
            orderLimit: this.subscriptionOrderLimit,
            validity: this.subscriptionValidity,
            pickupLocationType: this.pickupLocationType,
            destinationType: this.destinationType,
            destinationPlaceList: this.selectedBoundedPlaces,
            discount: this.subscriptionDiscount,
            features: this.subscriptionFeatures,
        }
        const response = await repository.subscription.createNewSubscription(data);
        this.loading = false
        if (response.success) {
            this.resetData()
            this.clearFormData();
            this.showSuccessMessage('Subscription Created');
            return
        }
        this.showErrorMessage(response.data)
    },

    
    validateSubscriptionData () {
      const returnValue = new Object()
      if (!this.subscriptionName) {
        this.subscriptionNameError = true
        this.subscriptionNameErrorMessage = 'Enter a name'
        returnValue.success = false
        return returnValue
      }
      if (!this.subscriptionAmount || this.subscriptionAmount < 1) {
        this.subscriptionAmountError = true
        this.subscriptionAmountErrorMessage = 'Enter a valid subscription amount'
        returnValue.success = false
        return returnValue
      }
      if (!this.subscriptionOrderLimit || this.subscriptionOrderLimit < 1) {
        this.subscriptionOrderLimitError = true
        this.subscriptionOrderLimitErrorMessage = 'Enter a valid order limit'
        returnValue.success = false
        return returnValue
      }
      if (!this.subscriptionValidity || this.subscriptionValidity < 1) {
        this.subscriptionValidityError = true
        this.subscriptionValidityErrorMessage = 'Enter a subscription validity'
        returnValue.success = false
        return returnValue
      }
      if (!this.destinationType) {
        this.destinationTypeError = true
        this.destinationTypeErrorMessage = 'Select a destination type'
        returnValue.success = false
        return returnValue
      }
      if (this.selectedBoundedPlaces.length == 0) {
        this.destinationTypeError = true
        this.destinationTypeErrorMessage = 'Destination Information Not Complete'
        this.selectedBoundPlaceError = true
        this.selectedBoundPlaceErrorMessage = 'Destination Information Not Complete'
        returnValue.success = false
        return returnValue
      }
      if (!this.pickupLocationType) {
        this.pickupLocationTypeError = true
        this.pickupLocationTypeErrorMessage = 'Select a pickup location type'
        returnValue.success = false
        return returnValue
      }
        //   if (!this.subscriptionDiscount || this.subscriptionDiscount < 0) {
        //     this.subscriptionDiscountError = true
        //     this.subscriptionDiscountErrorMessage = 'Enter a valid discount'
        //     returnValue.success = false
        //     return returnValue
        //   }
      if (!this.subscriptionFeatures) {
        this.subscriptionFeaturesError = true
        this.subscriptionFeaturesErrorMessage = 'Enter subscription features'
        returnValue.success = false
        return returnValue
      }
      returnValue.success = true
      return returnValue
    },

    showSuccessMessage (message) {
      swal(message, "", "success")
    },
    showErrorMessage (message) {
      this.error = true
      this.errorMessage = message
    },

    resetData () {
      this.subscriptionNameError=false;
      this.subscriptionOrderLimitError=false;
      this.destinationCountryError=false;
      this.destinationStateError=false;
      this.selectedBoundPlaceError=false;
      this.subscriptionAmountError=false;
      this.subscriptionValidityError=false;
      this.pickupLocationTypeError=false;
      this.subscriptionDiscountError=false;
      this.destinationTypeError = false;
      this.selectedBoundedPlacesError = false;
      
      this.roleError = false
      this.success = false
      this.successMessage = ''
      this.error = false
      this.errorMessage = ''
    },
    clearFormData() {
        this.subscriptionName='';
        this.subscriptionAmount='';
        this.subscriptionOrderLimit='';
        this.subscriptionValidity='';
        this.destinationType='';
        this.resetDestinationData();
        this.pickupLocationType='';
        this.subscriptionDiscount='';
    }
  }
}
</script>

<style scoped>
@import url('/assets/styles/vendor_components/sweetalert/sweetalert.css');
@import "~vue-wysiwyg/dist/vueWysiwyg.css";
</style>

